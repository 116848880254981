<template>
  <div id="app">
    <Nav :pt="isIndex" />
    <div class="pageMain">
      <div class="p20">
        <div class="concertList" v-for="(item,key) in showList" :key="key">
          <el-row :gutter="10">
            <el-col :span="10">
              <router-link to="/concert/1" class="concertListPic">
                <!-- <div class="concertListState">未开票</div> -->
                <img :src="`${$baseUrl}${item.thumb}`">
              </router-link>
            </el-col>
            <el-col :span="14">
              <h3 class="concertListTitle ellipsis3">
                {{item.title}}
              </h3>
              <div class="concertListRow"><b>演出时间:</b>{{item.showtime}}</div>
              <div class="concertListRow"><b>演出场馆:</b>{{item.venue}}</div>
              <div class="concertListRow"><b>演出嘉宾:</b>{{item.actor}}</div>
            </el-col>
          </el-row>
          <div class="pt10">
            <a  @click="showDetail(item)" class="concertListBtn">演出详情</a>
          </div>
        </div>

        
        
        <!-- <div class="paging mt20">
          <span class="prev disable"><img src="@/assets/prev.png" width="6" /></span>
          <div class="digital">
            <span class="cut">1</span>
            <span>2</span>
            <span>3</span>
            <span>4</span>
            <span>5</span>
          </div>
          <span class="next"><img src="@/assets/next.png" width="6" /></span>
        </div> -->
      </div> 

      
      
      <Footer />

    </div>
    
  </div>
</template>

<style scoped>
 
</style>
<script>
import Nav from '@/components/nav.vue'
import Footer from '@/components/footer.vue'
export default {
  
  name: 'App',
  components: {
    Nav,
    Footer
  },
  data () {
    return {
      isIndex: false,
      showList: [],
      joinList:[
        {
          title:"招聘岗位名称1",
          othe:"时间/地点",
          describe:"苏州民族管弦乐团是由苏州市和高新区共同成立的职业乐团，为市级公益性文艺团体。乐团由曾经十三次赴维也纳金色大厅指挥的著名指挥家彭家鹏任艺术总监兼首席指挥，由著名二胡演奏家、作曲家朱昌耀任艺术指导。聘请中国音乐家协会名誉主席、著名作曲家赵季平，中国民族管弦乐学会会长、著名作曲家刘锡津，中国民族管弦乐学会副会长兼秘书长王书伟，中国民族管弦乐学会荣誉会长、著名作曲家顾冠仁任艺术顾问。乐团拥有演奏员九十余人，由海内外知名演奏家及中央、上海等九大音乐学院的优秀毕业生组成。",
          salary:"带薪年假，十四薪",
          show:true
        },{
          title:"招聘岗位名称2",
          othe:"时间/地点",
          describe:"苏州民族管弦乐团是由苏州市和高新区、著名作曲家顾冠仁任艺术顾问。乐团拥有演奏员九十余人，由海内外知名演奏家及中央、上海等九大音乐学院的优秀毕业生组成。",
          salary:"带薪年假，十四薪",
          show:false
        },{
          title:"招聘岗位名称3",
          othe:"时间/地点",
          describe:"乐团拥有演奏员九十余人，由海内外知名演奏家及中央、上海等九大音乐学院的优秀毕业生组成。",
          salary:"带薪年假，十四薪",
          show:false
        }
      ]
    }
  },
  computed: {
      
  },
  mounted() {
    this.getShowList()
  },
  methods:{
    joinView(index){
      this.joinList[index].show = !this.joinList[index].show
    },

    getShowList() {
      this.$ajax
        .get("/cms/api/showlist", {})
        .then((res) => {
          if (res.code == "200") {
            this.showList = res.data
            console.log(this.showList);
          }
        });
    },
    
    showDetail(item) {
      this.$router.push({ name: 'ConcertView', params: { id: item.id }})
    },
    
  },
}
</script>

